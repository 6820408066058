import MaskOne from '../assets/home/MaskOne.png';
import MaskThree from '../assets/home/MaskThree.png';
import MaskTwo from '../assets/home/MaskTwo.png';
import MaskFour from '../assets/home/MaskFour.png';

export const consultantData = [
  {
    image: MaskOne,
    title: 'Muksidul Manik',
    designation: '(FCMA)',
    subTitle: 'Sr. Consultant',
    content:
      'Muksitul Manik, a professional accountant in Bangladesh. He is a member of the Institute of Cost and Management Accountants of Bangladesh (ICMAB). He accomplished his post-graduation in Accounting from Jagannath University in Dhaka, Bangladesh',
  },
  {
    image: MaskTwo,
    title: 'Md. Shohel Khan',
    designation: '(ACMA)',
    subTitle: 'Consultant',
    content:
      'He is a Professional Accountant and Finance expert in Bangladesh. He is an Associate Member of the Institute of Cost and Management Accountants of Bangladesh (ICMAB).',
  },
  {
    image: MaskThree,
    title: 'Mohammed M Morshed Khan',
    designation: '(ITP)',
    subTitle: 'Consultant',
    content:
      'He is one of the Partner of Company Care & Associates since it’s begin and working as a Business consultant for Accounting, Corporate Affairs, Business Development, special audit, Investigation and Socio-Economic services provided by Company Care & Associates.',
  },
  {
    image: MaskFour,
    title: 'Imamul Haque',
    designation: '(CAP)',
    subTitle: 'Consultant',
    content:
      'He is a Professional Accountant and Finance expert, having experience of over 12 years in Banking sector, focused on Financial Management, Auditing, Financial & Business Analysis and Bank Finance.',
  }
];
