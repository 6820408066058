import React from 'react';
import ornament from '../assets/home/ornament.png';
import underLine from '../assets/home/underLine.png';
import { consultantData } from '../common/consultantData';
import ConsultantCard from './ConsultantCard';
const ConsultantSection = () => {
  return (
    <div
      className='bg-[#E1F0FF]'
      id='consultant'
    >
      <div className='containers py-20 '>
        <div className='flex justify-center items-center'>
          <div className='text-center'>
            <h4 className='customHeadingTwo'>Our Consultant</h4>
            <h1 className='customHeading '>
              Meet the Experts Behind Your Success
            </h1>
            <div className='flex justify-center items-center py-5'>
              <img
                src={underLine}
                alt='under-line'
              />
            </div>
          </div>
        </div>
        <div
          className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 md:gap-5 py-20 xl:px-8'
          style={{
            backgroundImage: `url(${ornament}), url(${ornament})`,
            backgroundRepeat: 'no-repeat, no-repeat',
            backgroundPosition: 'left bottom, right top',
            backgroundSize: 'auto, auto',
            top: '-22%',
          }}
        >
          {consultantData?.map((item, index) => (
            <ConsultantCard
              key={index}
              image={item?.image}
              title={item?.title}
              subTitle={item?.subTitle}
              designation={item?.designation}
              content={item?.content}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ConsultantSection;
