import React from "react";
import { Tooltip } from "react-tooltip";

const ConsultantCard = ({ image, title, subTitle, designation, content }) => {
  return (
    <>
      <div className=" border-[#01AEEF] border-[1px] rounded-3xl text-center  bg-white   ">
        <div className="bg-[#01AEEF] rounded-3xl h-[25%]">
          <div className=" flex justify-center items-start  ">
            <div className=" w-2/4 h-2/4 object-contain border-[2px] border-[#01AEEF] rounded-full bg-white mt-[10%]">
              <img className="w-full" src={image} alt="serviceone" />
            </div>
          </div>
        </div>

        <div className="mt-[28%] md:mt-[18%]">
          <h5 className="customHeadingTwo text-gray-950  text-[0.9rem] mx-5 font-semibold leading-8">
            {title} {" "}
            <span className="text-[#01AEEF]  text-[0.7rem] font-normal">
              {designation}
            </span>
          </h5>
          <h5 className="customHeadingTwo text-[#01AEEF] mb-3 text-[1rem] leading-8">
            {subTitle}
          </h5>
          <p className="customPeragraph px-6 text-[1rem] text-[#646363] hyphens-auto ">
            {content.slice(0, 120)} {" "}
            <span
              className="text-sm font-semibold cursor-pointer text-[#01AEEF]"
              data-tooltip-id={title}
            >
              ...more
            </span>
            <Tooltip id={title} style={{ backgroundColor: "#a3d7eb", color: "#222" , width: "20%", padding: "20px"}}>
                {content}
            </Tooltip>
          </p>
          <button className="border-[1px] mt-8 customBtn bg-white text-[#01AEEF] border-x-2 px-14 rounded-xl">
            View Bio
          </button>
        </div>
      </div>
    </>
  );
};

export default ConsultantCard;
